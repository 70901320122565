<template>
  <el-dialog title="充值" :visible="enterpriseRechargeVisible" width="600px" @close="closeDialog">
    <h4 class="title">专属账户信息</h4>
    <el-row>
      <el-col :span="24">帐户户名：{{ enterpriseRechargeData.platform_name }}</el-col>
      <el-col :span="24">帐户编号：{{ enterpriseRechargeData.sub_account }}</el-col>
      <el-col :span="24">结算帐户余额：{{ enterpriseRechargeData.balance| money }}元</el-col>
    </el-row>
    <h4 class="title">充值指导信息</h4>
    <el-row>
      <el-col :span="24">收款户名：{{ enterpriseRechargeData.platform_name }}</el-col>
      <el-col :span="24">收款账号：{{ enterpriseRechargeData.account }}</el-col>
      <el-col :span="24">收款银行：{{ enterpriseRechargeData.bankname }}</el-col>
      <el-col :span="24">收款支行：{{ enterpriseRechargeData.branchBank }}</el-col>
      <el-col :span="24">联行号：{{ enterpriseRechargeData.bank_line_no }}</el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    enterpriseRechargeVisible: Boolean,
    enterpriseRechargeData: Object
  },
  methods: {
    closeDialog () {
      this.$emit('update:enterpriseRechargeVisible', false);
    }
  }
};
</script>

<style>
.title {
  margin-bottom: 20px;
}
.el-col-24 {
  margin-bottom: 14px;
}
</style>
